import React from "react";
import Avatar from "./Avatar";
import { Tooltip } from "antd";

// Avatar do usuário passado como parâmetro, pode ser seguido pelo nome do usuário, pode conter um tooltip com o nome ou ser apenas a imagem de perfil
function User({
  user,
  avatarSize,
  fontStyle,
  objStyle,
  hideName = false,
  withTooltip = false,
  ...props
}) {
  const UserAvatar = (
    <div
      className={props.className || "d:f flx-d:r a-i:c m-b:1"}
      style={objStyle}
    >
      <Avatar avatar={user.profile_image} size={avatarSize} />
      {!hideName &&
        (user.name ? (
          <h6 className="m-b:0 d:i-b m-l:.6" style={fontStyle}>
            {user.name}
          </h6>
        ) : user.full_name ? (
          <h6 className="m-b:0 d:i-b m-l:.6" style={fontStyle}>
            {user.full_name}
          </h6>
        ) : (
          <h6 className="m-b:0 d:i-b m-l:1" style={fontStyle}>
            {user.first_name} {user.last_name}
          </h6>
        ))}
    </div>
  );

  return user ? (
    withTooltip ? (
      <Tooltip
        title={
          <span>
            {user.name
              ? user.name
              : user.full_name
              ? user.full_name
              : `${user.first_name} ${user.last_name}`}
          </span>
        }
        placement="top"
      >
        {UserAvatar}
      </Tooltip>
    ) : (
      UserAvatar
    )
  ) : null;
}

export default User;
