import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MDI from "@mdi/react";
import { Icon as IconI } from "@iconify/react";

import {
  mdiAccountCheck,
  mdiArrowLeft,
  mdiCalendarMonth,
  mdiCalendarWeek,
  mdiCalendarClock,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiDeleteOutline,
  mdiInboxRemove,
  mdiMinusCircleOutline,
  mdiPencil,
  mdiPlus,
  mdiEarth,
  mdiAccountMultiple,
  mdiAccount,
  mdiTrendingUp,
  mdiTrendingDown,
  mdiTrendingNeutral,
  mdiCheckboxMarkedCircle,
  mdiDotsVertical,
  mdiAccountEdit,
  mdiCheckboxMarkedCircleOutline,
  mdiLabelOutline,
  mdiSubdirectoryArrowLeft,
  mdiCheckAll,
  mdiAccountCircle,
  mdiRocketLaunchOutline,
  mdiSearchWeb,
  mdiForumOutline,
  mdiClose,
  mdiMenuDown,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
  mdiClockOutline,
  mdiAccountMultiplePlus,
  mdiFilterVariant,
  mdiCheckBoxOutline,
  mdiSwapHorizontal,
  mdiCreditCardOutline,
  mdiViewGridPlusOutline,
  mdiLink,
  mdiViewColumn,
  mdiCalendarToday,
  mdiBullseyeArrow,
  mdiHomeOutline,
  mdiProgressClock,
  mdiFlagCheckered,
  mdiCogOutline,
  mdiRobotOutline,
} from "@mdi/js";

import { CheckCircle, PlayCircle, XCircle, StopCircle } from "react-feather";
import house from "@iconify-icons/twemoji/house";
import checkbox from "@iconify-icons/twemoji/check-box-with-check";
import calendar from "@iconify-icons/twemoji/spiral-calendar";
import memo from "@iconify-icons/twemoji/memo";
import policeCarLight from "@iconify-icons/twemoji/police-car-light";
import bullseye from "@iconify-icons/twemoji/bullseye";
import pushpin from "@iconify-icons/twemoji/pushpin";
import oldKey from "@iconify-icons/twemoji/old-key";
import globe from "@iconify-icons/twemoji/globe-with-meridians";
import person from "@iconify-icons/twemoji/bust-in-silhouette";
import flag from "@iconify-icons/twemoji/triangular-flag";
import parallelTasks from "@iconify-icons/flat-color-icons/parallel-tasks";
import settings from "@iconify-icons/flat-color-icons/settings";
import people from "@iconify-icons/flat-color-icons/conference-call";
import exportIcon from "@iconify-icons/flat-color-icons/export";
import magnifyingGlassTiltedRight from "@iconify-icons/twemoji/magnifying-glass-tilted-right";
import partyPopper from "@iconify-icons/twemoji/party-popper";
import listCheck from "@iconify/icons-bi/list-check";
import chevronRight from "@iconify/icons-majesticons/chevron-right";

const I = styled.i`
  top: -1px;
  position: relative;
  svg {
    width: 1em;
    height: 1em;
  }
  svg.mdi-icon {
    width: 1.1em;
    height: 1.1em;
  }
`;

const icons = {
  clock: [0, mdiClockOutline],
  home: [0, mdiHomeOutline],
  calendar: [2, calendar],
  checkbox: [0, mdiCheckBoxOutline],
  workflows: [0, mdiRobotOutline],
  checkin: [2, listCheck],
  focus: [0, mdiBullseyeArrow],
  alert: [2, policeCarLight],
  settings: [0, mdiCogOutline],
  delete: [0, mdiDeleteOutline],
  edit: [0, mdiPencil],
  minus: [0, mdiMinusCircleOutline],
  plus: [0, mdiPlus],
  globe: [2, globe],
  people: [2, people],
  flag: [2, flag],
  person: [2, person],
  chevronLeft: [0, mdiChevronLeft],
  chevronRight: [0, mdiChevronRight],
  chevronUp: [0, mdiChevronUp],
  chevronDown: [0, mdiChevronDown],
  arrowLeft: [0, mdiArrowLeft],
  empty: [0, mdiInboxRemove],
  week: [0, mdiCalendarWeek],
  month: [0, mdiCalendarMonth],
  accountCheck: [0, mdiAccountCheck],
  in_progress: [1, PlayCircle],
  failed: [1, XCircle],
  success: [1, CheckCircle],
  okrs: [0, mdiFlagCheckered],
  earth: [0, mdiEarth],
  user: [0, mdiAccount],
  key: [2, oldKey],
  filter: [0, mdiFilterVariant],
  team: [0, mdiAccountMultiple],
  trend_up: [0, mdiTrendingUp],
  trend_down: [0, mdiTrendingDown],
  trend_neutral: [0, mdiTrendingNeutral],
  checkmarked_circle: [0, mdiCheckboxMarkedCircle],
  dotsVertical: [0, mdiDotsVertical],
  logout: [2, exportIcon],
  account_edit: [0, mdiAccountEdit],
  checkmarked_circle_outline: [0, mdiCheckboxMarkedCircleOutline],
  tag: [0, mdiLabelOutline],
  subArrowLeft: [0, mdiSubdirectoryArrowLeft],
  checkAll: [0, mdiCheckAll],
  accountCircle: [0, mdiAccountCircle],
  rocket: [0, mdiRocketLaunchOutline],
  research: [0, mdiSearchWeb],
  meet: [0, mdiForumOutline],
  close: [0, mdiClose],
  menuDown: [0, mdiMenuDown],
  navExpand: [0, mdiChevronDoubleRight],
  navCollapse: [0, mdiChevronDoubleLeft],
  addUser: [0, mdiAccountMultiplePlus],
  calendarClock: [0, mdiCalendarClock],
  switchCompany: [0, mdiSwapHorizontal],
  creditCard: [0, mdiCreditCardOutline],
  magnifyingGlass: [2, magnifyingGlassTiltedRight],
  partyPopper: [2, partyPopper],
  integrations: [0, mdiViewGridPlusOutline],
  link: [0, mdiLink],
  weekly: [0, mdiViewColumn],
  daily: [0, mdiCalendarToday],
  progress: [0, mdiProgressClock],
  checkList: [2, listCheck],
  robot: [0, mdiRobotOutline],
  chevronRightFilled: [2, chevronRight],
  not_started: [1, StopCircle],
};

const libraries = [
  (icon, style) => <MDI path={icon} style={style} className="mdi-icon" />,
  (Icon, style) => <Icon style={style} />,
  (icon, style) => <IconI icon={icon} style={style} />,
];

export default function Icon({ type, svgStyle, ...props }) {
  const match = icons[type] || icons[type.toLowerCase()];
  if (!match) return false;
  return <I {...props}>{libraries[match[0]](match[1], svgStyle)}</I>;
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};
