import React from "react";
import { Select } from "antd";
import "moment/locale/pt-br";
import useSWR from "swr";

import User from "../common/User";
import Icon from "../common/Icon";
import { useUser } from "../../context/UserContext";
import { get } from "../../services/api";

// Select dos usuários da plataforma, recebe as opções de usuários a serem selecionados e um objeto de estado {value: value, set: setValue} que vai conter os usuários selecionados
export default function SelectUsers({ value, onChange, ...props }) {
  const { data: users } = useSWR("/companies/members/", get);
  const [loggedUser] = useUser();

  // Componente que renderiza os múltiplos usuários selecionados dentro da caixa de seleção do select
  function assignedUserRender(props) {
    const { value, onClose } = props;
    if (value === -1) {
      return (
        <span>
          <Icon
            type="accountCircle"
            className="f:3"
            style={{ color: "#6C757D" }}
          />
          <span className="pos:r top:-.1 p-l:.5">Desatribuído</span>
        </span>
      );
    }
    return users
      .filter((user) => user.id === value)
      .map((user) => (
        <div
          key={user.id}
          className="d:f flx-d:r m-y:.3 m-x:.1 p-x:.4 p-t:.1 p-b:.4"
          style={{
            background: "#A6B3F7",
            borderRadius: "50px",
          }}
        >
          <User
            className="m:0"
            user={user}
            avatarSize={20}
            fontStyle={{ fontSize: "14px" }}
          />
          <Icon className="m-l:.2" type="close" onClick={onClose} />
        </div>
      ));
  }

  return (
    <Select
      mode="multiple"
      showArrow
      id="user-selector"
      showSearch={false}
      value={value.length !== 0 ? value : -1}
      onSelect={(value) => {
        if (value === -1) {
          onChange([]);
        }
      }}
      onChange={(values) => {
        onChange(values.filter((value) => value !== -1));
      }}
      tagRender={assignedUserRender}
      suffixIcon={
        <Icon
          type="menuDown"
          className="pos:r top:-.4 f:2 p-b:2"
          style={{ color: "#6C757D" }}
        />
      }
      style={{ width: "100%" }}
      {...props}
    >
      <Select.Option value={-1}>
        <span>
          <Icon
            type="accountCircle"
            style={{ color: "#6C757D", fontSize: "27px" }}
          />
          <span className="pos:r top:-.1 m-l:.5">Desatribuído</span>
        </span>
      </Select.Option>

      {users?.map((user) => (
        <Select.Option value={user.id}>
          {user.id === loggedUser.id ? (
            <>
              <User
                user={user}
                avatarSize={28}
                className="m-y:.2 d:i pos:r"
                fontStyle={{
                  fontSize: "14px",
                  top: "2px",
                }}
                hideName
              />
              <h6
                className="m-b:0 d:i-b m-l:.6 pos:r"
                style={{ fontSize: "14px", top: "2px" }}
              >
                Atribuir à mim
              </h6>
            </>
          ) : (
            <User
              user={user}
              avatarSize={28}
              className="m-y:.2 d:i pos:r"
              fontStyle={{
                fontSize: "14px",
                top: "2px",
              }}
            />
          )}
        </Select.Option>
      ))}
    </Select>
  );
}
