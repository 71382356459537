import React from "react";
import packageInfo from "../../../package.json";

export default function VersionTag() {
  return (
    <small style={{ textAlign: "center", width: "100%" }}>
      v{packageInfo.version}
    </small>
  );
}
