import React from "react";
import { PlayCircle, XCircle, CheckCircle, Clock } from "react-feather";

const iconProps = {
  size: 15,
  fillOpacity: "0.2",
  className: "mr-1 feather-icon",
};

export const goalsStatus = {
  // ACTIVE: {
  //   value: 'NOT_COMPLETED',
  //   label: 'Ativo',
  //   color: 'var(--primary)',
  //   icon: (
  //     <PlayCircle color="var(--primary)" fill="var(--primary)" {...iconProps} />
  //   ),
  // },
  COMPLETED: {
    value: "COMPLETED",
    label: "Concluído",
    color: "var(--success)",
    icon: (
      <CheckCircle
        color="var(--success)"
        fill="var(--success)"
        {...iconProps}
      />
    ),
  },
  SUCCESS: {
    value: "SUCCESS",
    label: "Sucesso",
    color: "var(--success)",
  },
  FAILED: {
    value: "FAILED",
    label: "Falhado",
    color: "var(--danger)",
    icon: <XCircle color="var(--danger)" fill="var(--danger)" {...iconProps} />,
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "Em progresso",
    color: "var(--primary)",
    icon: (
      <PlayCircle color="var(--primary)" fill="var(--primary)" {...iconProps} />
    ),
  },
  NOT_STARTED: {
    value: "NOT_STARTED",
    label: "Não iniciado",
    color: "var(--gray)",
    icon: <Clock color="var(--gray)" fill="var(--gray)" {...iconProps} />,
  },
};

export const tasksStatus = {
  COMPLETED: {
    value: "COMPLETED",
    label: "Concluído",
    color: "var(--success)",
    icon: (
      <CheckCircle
        color="var(--success)"
        fill="var(--success)"
        {...iconProps}
      />
    ),
  },
  NOT_COMPLETED: {
    value: "NOT_COMPLETED",
    label: "Em progresso",
    color: "var(--primary)",
    icon: (
      <PlayCircle color="var(--primary)" fill="var(--primary)" {...iconProps} />
    ),
  },

  LATE: {
    value: "LATE",
    label: "Atrasado",
    color: "var(--danger)",
    icon: <Clock color="var(--danger)" fill="var(--danger)" {...iconProps} />,
  },
};
export const resultsStatus = {
  COMPLETED: {
    value: "COMPLETED",
    label: "Concluído",
    color: "var(--success)",
  },
  FAILED: {
    value: "FAILED",
    label: "Falhado",
    color: "var(--danger)",
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "Em progresso",
    color: "var(--primary)",
  },
  NOT_STARTED: {
    value: "NOT_STARTED",
    label: "Não iniciado",
    color: "var(--warning)",
  },
};
export const stepsStatus = {
  ...goalsStatus,
  NOT_STARTED: {
    value: "NOT_STARTED",
    label: "Não iniciado",
    color: "var(--warning)",
    icon: <Clock color="var(--warning)" fill="var(--warning)" {...iconProps} />,
  },
};

export const statusOptions = {
  ...stepsStatus,
  ...tasksStatus,
};

export default function Status({
  status,
  HtmlElement = "small",
  hideText = false,
  textColor,
  ...otherProps
}) {
  if (status == "SUCCESS") status = "COMPLETED";
  status = statusOptions[status];
  return (
    <HtmlElement title={status.label} {...otherProps}>
      {status.icon}
      {!hideText && (
        <span className={textColor || "text-gray"}>{status.label}</span>
      )}
    </HtmlElement>
  );
}
