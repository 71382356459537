import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import ptBr from "antd/lib/locale/pt_BR";

import "./styles/antd-override.less";
import "./styles/index.sass";

import MainRouter from "./routes/MainRouter";
import { configureStore, history } from "./redux/store";

import HyperDX from "@hyperdx/browser";

export const store = configureStore();

class App extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.xsrfHeaderName = "X-CSRFToken";
    axios.defaults.withCredentials = true;
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    if (process.env.NODE_ENV === "production") {
      HyperDX.init({
        apiKey: "c84731ba-5a1e-46c1-82de-a80cb0c3c7e2",
        service: "feito-frontend",
        consoleCapture: true, // Capture console logs (default false)
        advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
      });
    }
  }
  render() {
    return (
      <ConfigProvider locale={ptBr}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <MainRouter />
          </ConnectedRouter>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default App;
