import React from "react";
import styled from "styled-components";
import { Avatar as AAvatar } from "antd";

import Tooltip from "./Tooltip";

const StyledAvatar = styled(AAvatar)`
  img {
    width: 100%;
  }
`;

function Avatar({
  avatar,
  children,
  name,
  avatarSize,
  color = undefined,
  ...avatarProps
}) {
  const Wrapper = name ? Tooltip : React.Fragment;
  const props = name ? { title: name } : {};

  return (
    <Wrapper {...props}>
      <StyledAvatar
        src={avatar}
        size={avatarSize}
        {...avatarProps}
        style={{ backgroundColor: color }}
      >
        {children && <span>{children}</span>}
      </StyledAvatar>
    </Wrapper>
  );
}

export default Avatar;
