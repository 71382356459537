import React from "react";
import { Avatar, Form, Tooltip } from "antd";

import Button from "../common/Button";
import MemberSelect from "../common/forms/MemberSelect";
import PopoverForm from "../common/PopoverForm";

export default function UserPopover({ objUsers, onUpdate }) {
  const formRef = React.useRef();

  return (
    <PopoverForm
      ref={formRef}
      title="Responsável pelo Foco"
      initialValues={{ users: objUsers.map(({ id }) => id) }}
      onFinish={onUpdate}
      content={
        <Form.Item noStyle name="users">
          <MemberSelect
            mode="multiple"
            className="w:full"
            onBlur={() => document.getElementById("popover-confirm").click()}
          />
        </Form.Item>
      }
    >
      {objUsers?.length > 0 ? (
        <span>
          {objUsers?.map((user) => (
            <Tooltip
              effect="dark"
              title={
                user.name
                  ? user.name
                  : user.full_name
                  ? user.full_name
                  : `${user.first_name} ${user.last_name}`
              }
              placement="top"
            >
              <Avatar src={user.profile_image} size={30} />
            </Tooltip>
          ))}
        </span>
      ) : (
        <Button type="white" shape="circle" icon="addUser" />
      )}
    </PopoverForm>
  );
}
