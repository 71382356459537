import React, { useState } from "react";
import { useDispatch } from "react-redux";

import RemoveAlert from "../../common/RemoveAlert";
import InputField from "../../common/forms/InputField";
import Checkin from "./Checkin";
import MinifiedCheckin from "./MinifiedCheckin";
import { editCheckin, deleteCheckin } from "../../../redux/checkins/actions";
import TagSelector from "../../tags/TagSelector";

export default function EditableCheckin({ data, date, minified = false }) {
  const [editing, setEditing] = useState(false);
  const [selectingTag, setSelectingTag] = useState(false);
  const dispatch = useDispatch();

  const handlePatch = (values) => {
    dispatch(editCheckin({ id: data.id, ...values }));
  };

  const handleChangeStatus = (e) => {
    handlePatch({ finished: e.target.checked });
  };

  const handleDelete = () =>
    RemoveAlert({
      customTitle: "Você tem certeza que deseja remover este checkin?",
      continueCallback: () => dispatch(deleteCheckin(data.id)),
    });

  const handleBlur = (e) => {
    if (selectingTag) return;
    if (e.target.value.length > 0 && e.target.value !== data.title)
      handlePatch({ title: e.target.value?.replace("#", "") });

    setEditing(false);
  };

  const handleTrackKeys = (e) => {
    // if enter pressed
    if (e.keyCode === 13) e.target.blur();

    // if # pressed
    if (e.keyCode === 51) {
      e?.preventDefault();
      setSelectingTag(true);
      setTimeout(() => {
        document.getElementById("#tag-selector").focus();
      }, 300);
    }
  };

  const handleSelectTags = (value) => {
    handlePatch({ tags: value?.tags });
    setSelectingTag(false);
    document.getElementById("checkin-input")?.focus();
  };

  return (
    <div className="d:f a-i:c pos:r">
      {editing ? (
        <InputField
          defaultValue={data.title}
          onBlur={handleBlur}
          autoFocus
          onKeyUp={handleTrackKeys}
          maxLength={150}
          id="checkin-input"
        />
      ) : minified ? (
        <MinifiedCheckin
          data={data}
          date={date}
          setEditing={setEditing}
          handlePatch={handlePatch}
          handleDelete={handleDelete}
          handleChangeStatus={handleChangeStatus}
        />
      ) : (
        <Checkin
          data={data}
          setEditing={setEditing}
          handlePatch={handlePatch}
          handleDelete={handleDelete}
          handleChangeStatus={handleChangeStatus}
        />
      )}

      {editing && selectingTag && (
        <div
          className="pos:a"
          style={{
            bottom: -20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <TagSelector onChange={handleSelectTags} />
        </div>
      )}
    </div>
  );
}
