import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Select } from "antd";
import useSWR from "swr";

import { get } from "../../services/api";
import ManageTags from "./ManageTags";

const { Option } = Select;

const ColorSquare = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;

export default function TagSelector({ value, onChange }) {
  const [form] = Form.useForm();
  const { data: tags } = useSWR("/tags/", get);

  useEffect(() => {
    // Ensure to set up a proper cleanup if needed
    return () => {
      onChange(form.getFieldsValue(true));
    };
  }, [form, onChange]);

  const handleSelectChange = (selectedTags) => {
    // Assuming `value` and the expected format for `onChange` is an array of tag IDs
    onChange(selectedTags);
  };

  return (
    <>
      <Form form={form} initialValues={{ tags: value }}>
        <Form.Item noStyle name="tags">
          <Select
            mode="multiple"
            placeholder="Select tags"
            onChange={handleSelectChange}
            style={{ width: "100%" }}
            id="#tag-selector"
          >
            {tags?.map((tag) => (
              <Option key={tag.id} value={tag.id}>
                <ColorSquare style={{ backgroundColor: `${tag.color}` }} />
                {tag.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
}
